import decode from "jwt-decode";
import { useRef } from "react";
import "flag-icons/css/flag-icons.min.css";

const currencyVal = localStorage.getItem("currency") ?? "NGN";
export const fmtCurrency = (
  value = 0,
  currency = currencyVal,
  isDivided = true,
  canBeFloat = false
) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currencyDisplay: "symbol",
    currency,
  });

  if (canBeFloat) {
    return isDivided
      ? formatter.format(parseFloat(value))
      : formatter.format(parseFloat(value) / 100);
  }

  return isDivided
    ? formatter.format(parseInt(value))
    : formatter.format(parseInt(value) / 100);
};
const getTokenExpirationDate = (token) => {
  if (!token) return null;
  const decoded = decode(token);
  if (!decoded.expires) {
    return null;
  }
  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.expires);
  return date;
};
export const isJWTExpired = (token) => {
  if (token) {
    return true;
  }
  const date = getTokenExpirationDate(token);
  /* offsetSeconds  */
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
};

export const isTokenValid = (token) => {
  if (!token) return false;
  const decoded = decode(token);
  const { isVerified } = decoded.user;
  const presentTime = new Date().getTime();
  const tokenExpiresTime = decoded.expires;
  if (tokenExpiresTime > presentTime && isVerified) {
    return true;
  } else {
    localStorage.removeItem("token");
    return false;
  }
};

export const ExistingLoginUser = (token) => {
  const decoded = decode(token);
  return decoded.user.userGroup.group.name;
};

export const advertiserUserType = (token) => {
  const decoded = decode(token);
  return decoded.user.accountType;
};

// export const getFlagEmoji = (countryCode) => {
//   // If countryCode is null, undefined, or not a valid string, return a fallback
//   if (!countryCode || typeof countryCode !== "string") {
//     return "🇺🇸"; // Placeholder emoji (white flag) or choose another fallback
//   }

//   // Handle special case for EU
//   if (countryCode === "EU") {
//     return "🇪🇺"; // European Union flag emoji
//   }

//   // Ensure the country code is uppercase and only two letters long
//   const normalizedCode = countryCode.toUpperCase().slice(0, 2);

//   // Convert country code to corresponding flag emoji
//   const flagEmoji = normalizedCode.replace(/./g, (char) =>
//     String.fromCodePoint(127397 + char.charCodeAt())
//   );

//   return flagEmoji;
// };

export const getFlagEmoji = (countryCode) => {
  // If countryCode is null, undefined, or not a valid string, return a fallback
  if (!countryCode || typeof countryCode !== "string") {
    return <span className="fi fi-us"  style={{width: "16px", height: "16px"}}  />; // Placeholder USA flag as fallback
  }

  // Handle special case for EU
  if (countryCode === "EU") {
    return <span className="fi fi-eu" style={{width: "16px", height: "16px"}} />;
  }

  // Ensure the country code is lowercase and only two letters long for the flag-icons class
  const normalizedCode = countryCode.toLowerCase().slice(0, 2);

  // Return the flag icon with the appropriate class
  return <span className={`fi fi-${normalizedCode}`}  style={{width: "16px", height: "16px"}}  />;
};

// Dynamic utility to fetch the currency symbol using Intl.NumberFormat
export const getCurrencySymbol = (currencyCode) => {
  try {
    // Use Intl.NumberFormat to get the currency symbol for the given currencyCode
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
    });

    // Extract the symbol from a formatted value
    const parts = formatter.formatToParts(1);
    const symbolPart = parts.find((part) => part.type === "currency");

    return symbolPart ? symbolPart.value : "$"; // Default to $ if no symbol found
  } catch (e) {
    // Fallback to a default symbol (e.g., $) if the currency code is invalid
    return "$";
  }
};

// Optional: function to get the currency name
const currencyNames = {
  NGN: "Naira",
  USD: "Dollar",
  // CAD: "Canadian Dollar",
  // GBP: "Pound Sterling",
  // EUR: "Euro",
  // AUD: "Australian Dollar",
  // JPY: "Japanese Yen",
  // INR: "Indian Rupee",
  // CNY: "Chinese Yuan",
  // MXN: "Mexican Peso",
  // BRL: "Brazilian Real",
  // ZAR: "Rand",
  // RUB: "Russian Ruble",
  // KES: "shilling",
  // Add more currency codes to names as needed
};

const currencySymbol = {
  NGN: "₦",
  USD: "$",
  GBP: "£",
  // EUR: "€",
  // KES: "KSh",
  // ZAR: "R",
  // USD: "$",
};
const countryByCurrency = {
  NGN: "Nigeria",
  USD: "United States",
  // GBP: "United Kingdom",
  // EUR: "EU",
  // KES: "Kenya",
  // ZAR: "South Africa",
};

const getCurrencyByCountry = {
  Nigeria: "NGN",
  "United States": "USD",
  // "United Kingdom": "GBP",
  // EU: "EUR",
  // Kenya: "KES",
  // "South Africa": "ZAR",
};

export const getCurrencyName = (currencyCode) => {
  return currencyNames[currencyCode] || "Currency name not found";
};
export const getCurrencySymbols = (currencyCode) => {
  return currencySymbol[currencyCode] || "Currency name not found";
};
export const getCountyByCurrency = (currencyCode) => {
  return countryByCurrency[currencyCode] || "Currency name not found";
};

export const getMyCountryCurrency = (countryName) => {
  return getCurrencyByCountry[countryName] || "USD";
}
export const useDebounce = (func, delay) => {
  const debounceTimeout = useRef(null);

  return (...args) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
