import React, { useState, useEffect } from "react";
import "./css/search-radio-station.css";
import RecentSearch from "./RecentSearch.js";
import countrydata from "../../countries+states.json";
import {
  GET_BROADCASTERS_REQ,
  GET_BROADCASTERS,
} from "../../components/GraphQL/Queries";
import request from "graphql-request";
import { URL } from "../../config/url";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
import { getMyCountryCurrency } from "../../utils/functions.js";

const SearchRadioStation = ({
  stationLocation,
  status,
  setCountryName,
  setIsSearching,
  setCountryStateNames,
}) => {
  const [states, setStates] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [countrystatename, setCountryStateName] = useState({
    countryName: "",
    stateName: "",
    isSearch: false
  });

  const [location, setLocation] = useState({
    country: "",
    region: "",
  });

  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: 0,
    });
  }, []);

  useEffect(() => {
    const geoSuccess = (position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    };
    navigator.geolocation.getCurrentPosition(geoSuccess);
  }, []);

  useEffect(() => {
    const fetchCountryState = async () => {
      if (latitude && longitude) {
        try {
          setIsSearching(true);
          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
          const response = await axios.get(apiUrl);
          const { country, state } = response?.data?.address;
          localStorage.setItem("currency", getMyCountryCurrency(country));
          const firstWord = state.split(" ")[0];
          setLocation({
            country,
            region: firstWord,
          });
          setCountryStateName({
            countryName: country,
            stateName: firstWord,
          });
          setCountryStateNames({
            countryName: country,
            stateName: firstWord,
          });
          setCountryName(country);

          request({
            url: URL,
            document: GET_BROADCASTERS,
            variables: {
              page: 1,
              itemsPerPage: 50,
              country,
              state: firstWord,
              currency: getMyCountryCurrency(country),
            },
          }).then((data) => {
            setBroadcasters(data?.guest?.getBroadcasters?.broadcasters);
            setIsSearching(false);
          });

          setSearcStatus(true);
        } catch (error) {
          setLocation({
            country: "",
            region: "",
          });

          setCountryStateName({
            countryName: "",
            stateName: "",
          });
          setIsSearching(false);
        }
      }
    };
    fetchCountryState();
  }, [latitude, longitude]);

  useEffect(() => {
    if (location.country) {
      const getStateData = countrydata.find(
        (country) => country.name === location.country
      ).states;
      setStates(getStateData);
    }
  }, [location]);

  const handleCountry = (e) => {
    setLocation({
      country: "",
      region: "",
    });
    const getCountryId = e.target.value;
    const getStateData = countrydata.find(
      (country) => country.name === getCountryId
    ).states;
    setStates(getStateData);
    setCountryStateName({ ...countrystatename, countryName: e.target.value, stateName: '' });
    setCountryStateNames({ ...countrystatename, countryName: e.target.value,  stateName: '' });
  };

  const handleState = (e) => {
    setLocation({
      country: "",
      region: "",
    });
    setCountryStateName({ ...countrystatename, stateName: e.target.value });
    setCountryStateNames({ ...countrystatename, stateName: e.target.value });
  };
  const [broadcasters, setBroadcasters] = useState([]);
  stationLocation(broadcasters);
  const [searchStatus, setSearcStatus] = useState(false);
  status(searchStatus);
  
  const handleSearch = () => {
    if(!countrystatename.countryName || !countrystatename.stateName){
      return
    }
    setCountryStateName((prev) => ({
      ...prev,    // Spread the previous state to preserve other values
      isSearch: true,  // Update the isSearch property
    }));

  };

  const handleSubmit = (e) => {
    setIsSearching(true);
    e.preventDefault();
    if (!countrystatename.isSearch) return
    request({
      url: URL,
      document: GET_BROADCASTERS,
      variables: {
        page: 1,
        itemsPerPage: 100,
        country: countrystatename.countryName,
        state: countrystatename.stateName,
        currency: getMyCountryCurrency(countrystatename?.countryName),
      },
    }).then((data) => {
      setBroadcasters(data?.guest.getBroadcasters.broadcasters);
      // console.log("data?.guest.getBroadcasters.broadcasters",data?.guest.getBroadcasters.broadcasters)
      setIsSearching(false);
    });
    setSearcStatus(true);
    setIsSearching(true);
  };

  

  return (
    <section id="search-radio-station" data-aos="fade-up">
      <div>
        <h1 data-aos="fade-right">
          S
          <span className="lower_text">
            earch for and discover <br />
            your preferred radio station
          </span>
        </h1>
        <form onSubmit={handleSubmit} data-aos="fade-up">
          <div>
            <div>
              <select id="countries" onChange={(e) => handleCountry(e)}>
                {/* <option selected>Select Country</option> */}
                {location.country ? (
                  <option selected>{location.country}</option>
                ) : (
                  <option selected>Select Country</option>
                )}
                {countrydata.map((getcountry, index) => (
                  <option value={getcountry.name} key={index}>
                    {getcountry.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select id="states" onChange={(e) => handleState(e)}>
                {/* <option selected>Select State</option> */}
                {location.region ? (
                  <option selected>{location.region}</option>
                ) : (
                  <option selected>Select State</option>
                )}
                {states.map((getState, index) => (
                  <option value={getState.name} key={index}>
                    {getState.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button type="submit" onClick={handleSearch}>Find a Radio Station</button>
        </form>
        <div>
          <h2>Popular Searches</h2>
          <div
            onBlur={(e) => {
              e.target.style.scroll = "hidden";
            }}
          >
            <div>
              <RecentSearch searchName="Lagos State" />
              <RecentSearch searchName="Oyo State" />
              <RecentSearch searchName="Federal Capital Territory" />
              <RecentSearch searchName="Ogun State" />
              <RecentSearch searchName="Osun State" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchRadioStation;
